import React, { Fragment, useEffect } from 'react';
import { getCookieConsentValue } from 'react-cookie-consent';
import ReactGA from 'react-ga4';
import { BiCertification } from 'react-icons/bi';
import { BsFileRuledFill } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { MdOutlineEmail } from 'react-icons/md';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { MStatisticsCard } from '../../components/ui/MStatisticsCard';

if (getCookieConsentValue()) {
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID, {
    testMode: process.env.NODE_ENV === 'test'
  });
}

/**
 * Renders the "About" page. Gives general info about Magnes and Nushu.
 * @return {jsx} The about page component.
 */
export const About = () => {
  // Track no. of visitors.
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: '/compare', title: 'About Page' });
  }, []);
  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Stack spacing={2} sx={{ height: '100%', width: '100%' }}>
      <Grid container spacing={5} justifyContent="center" align="center">
        <Grid item xs={12} md={4} sx={{ [theme.breakpoints.up('md')]: { height: '100%' } }}>
          <MStatisticsCard title={<big>Medical Certification</big>} icon={BiCertification}>
            <Fragment>
              <Box>
                <Typography sx={{ fontSize: 18 }}>Currently intended for</Typography>
                <Typography color="secondary" sx={{ fontSize: 25 }}>
                  <strong>Clinical Research</strong>
                </Typography>
              </Box>
              <Box>
                <Divider sx={{ margin: 2 }} />
                <Typography sx={{ fontSize: 20 }}>
                  Medical certification is in the works.
                </Typography>
              </Box>
            </Fragment>
          </MStatisticsCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MStatisticsCard title={<big> Data Privacy </big>} icon={BiCertification}>
            <Fragment>
              <Box sx={{ padding: 1, height: 100 }}>
                <Typography sx={{ fontSize: 14 }} align="center">
                  We give the utmost your and your patients&apos; data privacy and security. That is
                  why we built our platform on Pryv, which is a Swiss data & privacy management
                  software. For more information on them and our data privacy policy see the links
                  below.
                </Typography>
              </Box>
              <Divider sx={{ [theme.breakpoints.down('xl')]: { display: 'none' } }} />
              <Box
                display="flex"
                align="center"
                justifyContent="center"
                sx={{
                  display: 'flex',
                  height: 80,
                  [theme.breakpoints.down('xl')]: { height: 160 }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    align: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '50%'
                  }}>
                  <a
                    href="https://www.pryv.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <img
                      src="https://www.pryv.com/wp-content/themes/pryv2019/assets/img/Logo-Pryv@3x.svg"
                      height={isSmallOrLess ? '20' : '60'}
                    />
                  </a>
                </Box>
                <Divider
                  orientation="vertical"
                  sx={{ [theme.breakpoints.down('xl')]: { display: 'none' } }}
                />
                <Box display="flex" alignItems="center" justifyContent="center" width={'50%'}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<BsFileRuledFill />}
                    href="https://www.magnes.ch/magnes_privacy_notice_nushu.html"
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{ fontSize: 10 }}>
                    Our Policy
                  </Button>
                </Box>
              </Box>
            </Fragment>
          </MStatisticsCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <MStatisticsCard title={<big>Contact Us</big>} icon={BiCertification}>
            <Fragment>
              <Box sx={{ padding: 1 }}>
                <Typography sx={{ fontSize: 14 }} align="center">
                  Feel free to touch bases with us, with any questions, bug reports and/or feedback
                  you may have.
                </Typography>
              </Box>
              <Divider />
              <Box
                display="flex"
                spacing={2}
                align="center"
                justifyContent="space-around"
                sx={{ display: 'flex', flexGrow: 1 }}>
                <Box
                  alignItems="center"
                  justifyContent="center"
                  sx={{ display: 'flex', height: '100%', width: '50%' }}>
                  <Typography style={{ fontSize: 14 }} align="center" justify="center">
                    Magnes AG <br />
                    Selnaustrasse 5, 8001 Zürich <br />
                    Switzerland
                  </Typography>
                </Box>

                <Divider orientation="vertical" />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ width: '50%' }}>
                  <a href="mailto:support@magnes.ch">
                    <IconButton color="primary">
                      <MdOutlineEmail style={{ fontSize: 40 }} />
                    </IconButton>
                  </a>
                </Box>
              </Box>
            </Fragment>
          </MStatisticsCard>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <MStatisticsCard title={undefined} icon={CgWebsite}>
          <Box display="flex" sx={{ backgroundColor: 'white' }}>
            <iframe
              src="https://www.magnes.ch"
              title="NUSHU by Magnes AG"
              height={window.innerHeight - 150}
              width="100%"
              style={{ marginTop: -15 }}
            />
          </Box>
        </MStatisticsCard>
      </Box>
    </Stack>
  );
};
